import { useAuth } from "../auth/auth-provider";
import { useOrganization } from "../organizations/organization-provider";

function OrganizationSelector() {
  const { profile } = useAuth();
  const { setOrganization } = useOrganization();
  return (
    <section className="section">
      <div className="columns is-centered">
        <div className="column is-3">
          <nav className="panel">
            <p className="panel-heading">Select an organization</p>
            {profile.memberships.map((o) => (
              <a className="panel-block is-flex is-justify-content-space-between" key={o.id} onClick={() => setOrganization(o.id)}>
                {o.name}
                <span className="panel-icon">
                  <i className="fas fa-arrow-right" aria-hidden="true"></i>
                </span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </section>
  );
}

export default OrganizationSelector;
