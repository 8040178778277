import { getDay, startOfDay } from "date-fns";
import _ from "lodash";
import Chart from "react-apexcharts";
import { exercises } from "../exercises/data";

function calculateWeekdayProgress(dates, goal) {
  return _.chain(dates)
    .countBy(d => startOfDay(d).toISOString())
    .mapValues(v => Math.min(v, goal) / goal)
    .map((progress, day) => ({ day, progress }))
    .groupBy(({ day }) => getDay(day))
    .mapValues(v => _.sumBy(v, 'progress') / v.length)
    .map((progress, dayOfWeek) => ({ dayOfWeek, progress }))
    .value();
}

function getAverageGoalProgressSeries(data) {
  const weekdayProgress = _.chain(data)
    .map(({ warmups, stretches }) => {
      return {
        warmups: calculateWeekdayProgress(warmups, exercises.warmup.goal),
        stretches: calculateWeekdayProgress(stretches, exercises.stretch.goal),
      };
    })
    .flatMap(({ warmups, stretches }) => [...warmups, ...stretches])
    .groupBy(({ dayOfWeek }) => dayOfWeek)
    .mapValues(v => Math.round(_.sumBy(v, 'progress') / v.length * 100))
    .value();
  return Array.from({ length: 7 }, (_, index) => weekdayProgress[index] || 0);
}

const options = {
  chart: {
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 5
    }
  },
  dataLabels: {
    formatter: (v) => v + '%'
  },
  xaxis: {
    categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
    labels: {
      formatter: (v) => v + '%',
    }
  }
};

function AverageGoalProgressDayOfWeekChart({ data }) {
  const series = [{
    name: "Average Goal Progress",
    data: getAverageGoalProgressSeries(data)
  }];

  return (
    <Chart type="bar" height="250" width="100%" series={series} options={options} />
  );
}

export default AverageGoalProgressDayOfWeekChart;
