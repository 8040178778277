import { createContext, useContext, useMemo, useState } from "react";
import { useAuth } from "../auth/auth-provider";

const OrganizationContext = createContext(null);

function OrganizationProvider({ children }) {
  const { profile } = useAuth();
  const [update, setUpdate] = useState(0);

  const organization = useMemo(() => {
    if (!profile) {
      return null;
    }
    if (profile.memberships.length === 1) {
      return profile.memberships[0];
    }
    else if (profile.memberships.length > 1) {
      const id = localStorage.getItem('organization');
      if (id) {
        return profile.memberships.find(o => o.id === id);
      }
    }
    return null;
  }, [profile, update]);

  const setOrganization = (id) => {
    localStorage.setItem('organization', id);
    setUpdate(x => x + 1);
  };

  const value = {
    organization,
    setOrganization
  };

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
}

function useOrganization() {
  return useContext(OrganizationContext);
}

export { OrganizationProvider, useOrganization };
