import { getAnalytics, logEvent } from "firebase/analytics";
import { useState } from "react";
import { saveCompletedExercise } from "./api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { exercises } from "./data";
import { useOrganization } from "../organizations/organization-provider";
import * as Sentry from "@sentry/react";
import StretchInstructions from "./stretch-instructions";
import WarmupInstructions from "./warmup-instructions";

const analytics = getAnalytics();

function logVideoEvent(event, video, title) {
  logEvent(analytics, event, {
    video_current_time: video.currentTime,
    video_duration: video.duration,
    video_percent: (video.currentTime / video.duration) * 100,
    video_provider: 'self',
    video_title: title,
    video_url: null,
    visible: true
  });
}

function ExerciseVideo({ type }) {
  const progressThresholds = [10, 25, 50, 75];
  const [previousTime, setPreviousTime] = useState(0);
  return (
    <div className="video-container">
      <video controls className="video"
        onPlay={(e) => {
          logVideoEvent('video_start', e.target, type);
        }}
        onEnded={(e) => {
          logVideoEvent('video_complete', e.target, type);
        }}
        onTimeUpdate={(e) => {
          if (e.target.currentTime > previousTime && e.target.paused === false) {
            const currentPercent = (e.target.currentTime / e.target.duration) * 100;
            const previousPercent = (previousTime / e.target.duration) * 100;
            const crossedThreshold = progressThresholds.some((t) => currentPercent >= t && previousPercent < t);
            if (crossedThreshold) {
              logVideoEvent('video_progress', e.target, type);
            }
          }
          setPreviousTime(e.target.currentTime);
        }}>
        <source src={exercises[type].videoUrl} type="video/mp4"/>
      </video>
    </div>
  );
}

function ExerciseInstructions({ type }) {
  switch (type) {
    case 'warmup': return <WarmupInstructions />;
    case 'stretch': return <StretchInstructions />;
    default: return null;
  }
}

function ViewPage({ type }) {
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const [saving, setSaving] = useState(false);
  return (
    <section className="section">
      <div className="columns">
        <div className="column is-8">
          <ExerciseVideo type={type}/>
          <div className="buttons is-right">
            <button className="button is-success" disabled={saving} onClick={async () => {
              try {
                setSaving(true);
                await saveCompletedExercise(organization?.id, type);
                navigate('/exercises');
              }
              catch (e) {
                toast.error(`An error occurred: ${e.message}`);
                console.log(e);
                Sentry.captureException(e);
              }
              finally {
                setSaving(false);
              }
            }}>
              <span className="icon">
                <i className="fa-solid fa-check"></i>
              </span>
              <span>Complete</span>
            </button>
            <button className="button" disabled={saving} onClick={() => navigate('/exercises') }>
              <span className="icon">
                <i className="fa-solid fa-arrow-left"></i>
              </span>
              <span>Back</span>
            </button>
          </div>
        </div>
        <div className="column is-4">
          <ExerciseInstructions type={type}/>
        </div>
      </div>
    </section>
  );
}

export default ViewPage;
