import { useCallback, useEffect, useState } from "react";
import { getSites } from "../sites/api";
import { useOrganization } from "../organizations/organization-provider";
import EditSiteModal from "./edit-site-modal";

function SitesPage() {
  const { organization } = useOrganization();
  const [sites, setSites] = useState(null);
  const [modal, setModal] = useState(false);
  const [siteId, setSiteId] = useState(null);

  const loadSites = useCallback(async () => {
    const sites = await getSites(organization.id);
    setSites(sites);
  }, [organization]);

  useEffect(() => {
    loadSites();
  }, [loadSites]);

  const openModal = (siteId) => {
    setSiteId(siteId);
    setModal(true);
  };

  const closeModal = async () => {
    await loadSites();
    setModal(false);
  };

  if (!sites) {
    return null;
  }

  return (
    <>
      {modal && <EditSiteModal id={siteId} onClose={closeModal}/>}

      <div className="container">
        <h1 className="title">Sites</h1>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th width="1%"><button className="button is-small is-primary" onClick={() => openModal()}>Add</button></th>
            </tr>
          </thead>
          <tbody>
            {sites.map(site => (
              <tr key={site.id}>
                <td>{site.name}</td>
                <td>{[site.address?.street, site.address?.city, site.address?.state, site.address?.zip].filter(Boolean).join(', ')}</td>
                <td><button className="button is-small" onClick={() => openModal(site.id)}>Edit</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SitesPage;
