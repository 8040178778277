import Authenticated from "../auth/authenticated";
import OrganizationRoute from "../organizations/organization-route";
import { Navigate, Outlet } from "react-router-dom";
import { TrainingLayout } from "./training-layout";
import coursesRoutes from './courses/routes';
import sessionsRoutes from './sessions/routes';

const routes = [
  {
    path: "/training",
    element: <Authenticated><OrganizationRoute><TrainingLayout><Outlet/></TrainingLayout></OrganizationRoute></Authenticated>,
    children: [
      { path: "", element: <Navigate to="courses" replace/>},
      ...coursesRoutes,
      ...sessionsRoutes,
    ]
  }
];

export default routes;
