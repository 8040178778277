import cx from 'classnames';
import _ from 'lodash';
import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: '${label} is required'
  },
});

function Form({ formik, children, ...props }) {
  // using custom handleSubmit function to workaround an issue in formik where
  // promise rejection is logged as warning instead of being allowed to propagate
  // this prevents centralized error handling (such as Sentry) from occurring
  // https://github.com/jaredpalmer/formik/issues/2758
  // https://github.com/jaredpalmer/formik/pull/3341
  // https://github.com/jaredpalmer/formik/pull/3052
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  return (
    <form onSubmit={handleSubmit} {...props}>
      {children}
    </form>
  );
}

function Input({ formik, name, ...props }) {
  return (
    <input
      className={cx('input', { 'is-danger': _.get(formik.touched, name) && _.get(formik.errors, name) })}
      name={name}
      value={_.get(formik.values, name) || ''}
      onChange={formik.handleChange}
      {...props} />
  );
}

function Textarea({ formik, name, ...props }) {
  return (
    <textarea
      className={cx('textarea', { 'is-danger': _.get(formik.touched, name) && _.get(formik.errors, name) })}
      name={name}
      value={_.get(formik.values, name)}
      onChange={formik.handleChange}
      {...props} />
  );
}

function Checkbox({ formik, name, label, value, ...props }) {
  return (
    <label className="checkbox">
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={_.get(formik.values, name).includes(value)}
        onChange={formik.handleChange}
        {...props} />
        &nbsp;{label}
    </label>
  );
}

function Select({ formik, name, options, valueKey = 'value', labelKey = 'label', includeEmpty, ...props }) {
  return (
    <div className={cx('select', 'is-fullwidth', { 'is-danger': _.get(formik.touched, name) && _.get(formik.errors, name) })}>
      <select
        name={name}
        value={_.get(formik.values, name)}
        onChange={formik.handleChange}
        {...props}>
        {includeEmpty && <option value=""></option>}
        {options.map(({ [valueKey]: value, [labelKey]: label }) => <option key={value} value={value}>{label}</option>)}
      </select>
    </div>
  );
}

function Radio({ formik, name, label, value, ...props }) {
  return (
    <label className="radio">
      <input type="radio"
        name={name}
        value={value}
        checked={_.get(formik.values, name) === value}
        onChange={formik.handleChange}
        {...props}
      />
      <span className="ml-1">{label}</span>
    </label>
  );
}

function FieldError({ formik, name, className }) {
  if (_.get(formik.touched, name) && _.get(formik.errors, name)) {
    return <p className={cx('help is-danger', className)}>{_.get(formik.errors, name)}</p>;
  } else {
    return null;
  }
}

export { Form, Input, Textarea, Checkbox, Select, Radio, FieldError };
