import { useEffect, useState } from "react";
import { useOrganization } from "../organizations/organization-provider";
import { getOrganizationMemberships } from "../memberships/api";
import { getCompletedExercises } from "../exercises/api";
import { startOfWeek, startOfYear } from "date-fns";
import ActiveUsersChart from "./active-users-chart";
import GoalProgressChart from "./goal-progress-chart";
import AverageGoalProgressDayOfWeekChart from "./average-goal-progress-day-of-week-chart";

function ActivityPage() {
  const { organization } = useOrganization();
  const [data, setData] = useState();

  useEffect(() => {
    const load = async () => {
      const users = await getOrganizationMemberships(organization.id);
      const start = startOfWeek(startOfYear(Date.now()));
      const data = [];
      for (const user of users) {
        const completed = await getCompletedExercises(organization.id, start, user.uid);
        data.push({ ...user, ...completed });
      }
      setData(data);
    };
    load();
  }, [organization]);

  if (!data) {
    return null;
  }

  return (
    <div className="container">
      <h1 className="title">Activity</h1>
      <div>
        <p className="is-size-6">Active Users</p>
        <figure>
          <ActiveUsersChart data={data}/>
        </figure>
      </div>
      <div>
        <p className="is-size-6">Goal Progress</p>
        <figure>
          <GoalProgressChart data={data}/>
        </figure>
      </div>
      <div>
        <p className="is-size-6">Average Goal Progress - Day of Week</p>
        <figure>
          <AverageGoalProgressDayOfWeekChart data={data}/>
        </figure>
      </div>
    </div>
  );
}

export default ActivityPage;
