import { InlineWidget } from "react-calendly";

function ContactPage() {
  return (
    <section className="section">
      <h1 className="title">Contact Us</h1>
      <InlineWidget url="https://calendly.com/brian-jones-supraopus/30min" styles={{minWidth: '320px', height: '700px'}}/>
    </section>
  );
}

export default ContactPage;
