import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";

function GlobalErrorBoundary({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={Sentry.captureException}>
      {children}
    </ErrorBoundary>
  );
}

function ErrorBoundaryFallback() {
  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">An unexpected error has occurred.</h1>
          <h2 className="subtitle">Our team has been notified. You can refresh the page and try again.</h2>
          <button className="button is-primary" onClick={() => window.location.reload()}>
            <span className="icon">
              <i className="fa-solid fa-rotate"></i>
            </span>
            <span>Refresh</span>
          </button>
        </div>
      </div>
    </section>
  );
}

function useAsyncErrorBoundary() {
  const { showBoundary } = useErrorBoundary();
  return (fn) => {
    return (...args) => {
      const result = fn(...args);
      return Promise.resolve(result).catch(showBoundary);
    }
  };
}

export { GlobalErrorBoundary, useAsyncErrorBoundary };
