import Authenticated from "../auth/authenticated";
import ProfilePage from "./profile";
import { Outlet } from "react-router-dom";

const routes = [
  {
    path: "/user",
    element: <Authenticated><Outlet/></Authenticated>,
    children: [
      { path: "profile", element: <ProfilePage/> }
    ]
  }
];

export default routes;
