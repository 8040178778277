import { useAuth } from "../auth/auth-provider";
import { Navigate } from "react-router-dom";

function AuthenticatedRedirect({ children, to = "/dashboard" }) {
  const { user } = useAuth();
  if (user) {
    return <Navigate to={to} replace />;
  }
  return children;
}

export default AuthenticatedRedirect;
