import { useEffect, useState } from "react";
import { useOrganization } from "../../organizations/organization-provider";
import { getTrainingSession } from "./api";
import { getTrainingCourse } from "../courses/api";
import { useParams } from "react-router-dom";
import { getEmployees } from "../../employees/api";
import { download } from "../../documents/api";

function ViewPage() {
  const { organization } = useOrganization();
  const { id } = useParams();
  const [session, setSession] = useState(null);
  const [course, setCourse] = useState(null);
  const [employees, setEmployees] = useState(null);

  useEffect(() => {
    const loadSession = async () => {
      const session = await getTrainingSession(organization.id, id);
      setSession(session);
    };
    loadSession();
  }, [organization, id]);

  useEffect(() => {
    const loadCourse = async () => {
      if (session) {
        const course = await getTrainingCourse(organization.id, session.courseId);
        setCourse(course);
      }
    };
    loadCourse();
  }, [organization, session]);

  useEffect(() => {
    const loadEmployees = async () => {
      const employees = await getEmployees(organization.id);
      setEmployees(employees);
    };
    loadEmployees();
  }, [organization]);

  const handleDownload = (document) => {
    download(organization.id, document);
  };

  if (!(session && course && employees)) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Training Session</h1>
      <div className="block">
        <strong>Course</strong>
        <p>{course.title}</p>
      </div>
      <div className="block">
        <strong>Date/Time</strong>
        <p>{session.date.toLocaleString()}</p>
      </div>
      <div className="block">
        <strong>Location</strong>
        <p>{session.location}</p>
      </div>
      <div className="block">
        <strong>Instructor</strong>
        <p>{session.instructor}</p>
      </div>
      <div className="block">
        <strong>Attendees</strong>
        <div className="content">
          <ul>
            {session.attendees.map(id => (
              <li key={id}>
                {employees.find(e => e.id === id)?.name || "(Unknown employee)"}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="block">
        <strong>Documents</strong>
        <div className="content">
          <ul>
            {session.documents.map(document => (
              <li key={document.id}>
                <a onClick={() => handleDownload(document)}>
                  <span>{document.name}</span>
                  <span className="icon">
                    <i className="fas fa-download"></i>
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default ViewPage;
