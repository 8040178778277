import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../../organizations/organization-provider";
import { getTrainingSession, addTrainingSession, updateTrainingSession } from "./api";
import { useFormik } from "formik";
import { Form, Input, FieldError, Checkbox, Select } from "../../forms";
import { useEffect, useRef, useState } from "react";
import { getTrainingCourses } from "../courses/api";
import { getEmployees } from "../../employees/api";
import * as yup from "yup";
import { formatDateTimeLocal } from "../../utils";
import { upload } from "../../documents/api";
import { toast } from "react-toastify";

const MAX_FILE_SIZE_MB = 30;

const schema = yup.object({
  courseId: yup.string().label('Course').required(),
  date: yup.string().label('Date/Time').required()
    .test('is-valid-date', '${label} must be a valid date and time', value => {
      const date = new Date(value);
      return !isNaN(date.getTime());
    }),
  location: yup.string().nullable(),
  instructor: yup.string().nullable(),
  attendees: yup.array().of(yup.string()).default([]).min(1, 'At least one attendee is required'),
  documents: yup.array().of(
    yup.object().label('Document').test('is-valid-file-size', '${label} exceeds maximum file size', (value) => {
      return !(value?.file?.size > MAX_FILE_SIZE_MB * 1024 * 1024);
    })
  ).default([]),
});

function EditPage() {
  const { organization } = useOrganization();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const uploadToast = useRef(null);
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const loadCourses = async () => {
      const courses = await getTrainingCourses(organization.id);
      setCourses(courses);
    };
    const loadEmployees = async () => {
      const employees = await getEmployees(organization.id);
      setEmployees(employees);
    };
    loadCourses();
    loadEmployees();
  }, [organization]);

  const f = useFormik({
    initialValues: schema.default(),
    validationSchema: schema,
    onSubmit: async values => {
      const data = {
        ...values,
        documents: await uploadDocuments(values.documents),
        date: new Date(values.date).toISOString(),
      };
      if (id) {
        await updateTrainingSession(organization.id, id, data);
      } else {
        await addTrainingSession(organization.id, data);
      }
      navigate("/training/sessions");
    }
  });

  const uploadDocuments = async (documents) => {
    if (documents.some(d => d.file)) {
      uploadToast.current = toast.loading("Uploading documents...", { position: 'top-center' });
      try {
        const newDocuments = [];
        for (const document of documents) {
          const newDocument = await uploadDocument(document);
          newDocuments.push(newDocument);
        }
        return newDocuments;
      }
      finally {
        toast.dismiss(uploadToast.current);
      }
    } else {
      return documents;
    }
  }

  const uploadDocument = async (document) => {
    const { file, ...rest } = document;
    if (file) {
      const { id } = await upload(organization.id, file);
      return { ...rest, id };
    } else {
      return document;
    }
  }

  useEffect(() => {
    const load = async () => {
      if (loading && id) {
        const session = await getTrainingSession(organization.id, id);
        f.setValues({ ...session, date: formatDateTimeLocal(session.date) });
      }
      setLoading(false);
    };
    load();
  }, [organization, id, loading, f]);

  const handleFileChange = async (event) => {
    const documents = Array.from(event.target.files)
      .map((file) => ({
        file: file,
        name: file.name,
        size: file.size,
        type: file.type,
      }))
      .reduce((acc, file) => {
        if (!acc.find(d => d.name === file.name)) {
          acc.push(file);
        }
        return acc;
      }, [...f.values.documents]);
    f.setFieldValue("documents", documents);
    event.target.value = null;
  };

  const handleDeleteFile = (index) => {
    f.setFieldValue("documents", f.values.documents.filter((_, i) => i !== index));
  };

  if (loading) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Training Session</h1>
      <Form formik={f}>
        <fieldset disabled={f.isSubmitting}>
          <div className="columns">
            <div className="column is-two-thirds">
              <div className="field">
                <label className="label">Course</label>
                <div className="control">
                  <Select formik={f} name="courseId" options={[{ value: '', label: '' }, ...courses.map(c => ({ value: c.id, label: c.title }))]} />
                </div>
                <FieldError formik={f} name="courseId" />
              </div>
              <div className="field">
                <label className="label">Date/Time</label>
                <div className="control">
                  <Input formik={f} name="date" type="datetime-local" />
                </div>
                <FieldError formik={f} name="date" />
              </div>
              <div className="field">
                <label className="label">Location</label>
                <div className="control">
                  <Input formik={f} name="location" type="text" placeholder="Location" />
                </div>
                <FieldError formik={f} name="location" />
              </div>
              <div className="field">
                <label className="label">Instructor</label>
                <div className="control">
                  <Input formik={f} name="instructor" type="text" placeholder="Instructor" />
                </div>
                <FieldError formik={f} name="instructor" />
              </div>
            </div>
            <div className="column">
              <div className="panel">
                <p className="panel-heading">Attendees</p>
                {employees.map(employee => (
                  <div key={employee.id} className="panel-block">
                    <Checkbox formik={f} name="attendees" label={employee.name} value={employee.id} />
                  </div>
                ))}
                <FieldError formik={f} name="attendees" className="panel-block" />
              </div>
              <div className="panel">
                <p className="panel-heading">Documents</p>
                <div className="panel-block">
                  <div className="file is-small">
                    <label className="file-label">
                      <input className="file-input" type="file" multiple onChange={handleFileChange}/>
                      <span className="file-cta">
                        <span className="file-label">Add document(s)...</span>
                      </span>
                    </label>
                  </div>
                  <p className="help ml-2">(Maximum file size: {MAX_FILE_SIZE_MB}MB)</p>
                </div>
                {f.values.documents.map((d, i) => (
                  <div className="panel-block" key={i}>
                    <div style={{width: "100%"}}>
                      <div className="is-flex is-justify-content-space-between">
                        <span>{d.name}</span>
                        <button className="delete" type="button" onClick={() => handleDeleteFile(i)}></button>
                      </div>
                      <FieldError formik={f} name={`documents[${i}]`} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-primary" type="submit">Save</button>
            </div>
            <div className="control">
              <NavLink to="/training/sessions" className="button">Cancel</NavLink>
            </div>
          </div>
        </fieldset>
      </Form>
    </section>
  );
}

export default EditPage;
