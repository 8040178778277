import { getAuth } from 'firebase/auth';
import { arrayUnion, collection, doc, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import _ from 'lodash';

const auth = getAuth();
const firestore = getFirestore();

async function saveCompletedExercise(oid, type) {
  const now = new Date();
  const utcStartOfWeek = getUTCStartOfWeek(now);  
  const path = getExercisesPath(auth.currentUser.uid, oid);
  const docRef = doc(firestore, path, utcStartOfWeek.getTime().toString());  
  const key = type === 'warmup' ? 'warmups' : 'stretches';
  await setDoc(docRef, { start: utcStartOfWeek, [key]: arrayUnion(now) }, { merge: true });
}

async function getCompletedExercises(oid, start, uid = auth.currentUser.uid) {
  const path = getExercisesPath(uid, oid);
  const collectionRef = collection(firestore, path);
  const utcStartOfWeek = getUTCStartOfWeek(start);
  const q = query(collectionRef, where('start', '>=', utcStartOfWeek));
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map(doc => doc.data());

  const warmups = [];
  const stretches = [];
  data.forEach(e => {
    warmups.push(...(e.warmups ?? []));
    stretches.push(...(e.stretches ?? []));
  });

  return {
    warmups: _.chain(warmups).map(d => d.toDate()).sortBy().dropWhile(d => d < start).value(),
    stretches: _.chain(stretches).map(d => d.toDate()).sortBy().dropWhile(d => d < start).value()
  };
}

function getExercisesPath(uid, oid) {
  return oid
    ? `organizations/${oid}/memberships/${uid}/exercises`
    : `users/${uid}/exercises`;
}

function getUTCStartOfWeek(date) {
  const d = new Date(date);
  d.setUTCHours(0, 0, 0, 0);
  d.setUTCDate(d.getUTCDate() - d.getUTCDay());
  return d;
}

export { saveCompletedExercise, getCompletedExercises }
