import { useEffect, useState } from "react";
import { useOrganization } from "../organizations/organization-provider";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAsyncErrorBoundary } from "../site/global-error-boundary";
import { FieldError, Form, Input } from "../forms";
import { getOrganization, updateOrganization } from "../organizations/api";

const schema = yup.object({
  name: yup.string().label('Name').required(),
  address: yup.object({
    street: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
  }),
  phone: yup.string(),
});

function EditOrganizationModal({ onClose }) {
  const { organization: { id: organizationId } } = useOrganization();
  const [loading, setLoading] = useState(true);
  const asyncErrorBoundary = useAsyncErrorBoundary();

  const f = useFormik({
    initialValues: schema.default(),
    validationSchema: schema,
    onSubmit: asyncErrorBoundary(async (values) => {
      await updateOrganization(organizationId, values);
      onClose();
    })
  });

  useEffect(() => {
    const load = async () => {
      if (loading) {
        const organization = await getOrganization(organizationId);
        f.setValues(organization);
      }
      setLoading(false);
    };
    load();
  }, [organizationId, loading, f]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Form formik={f}>
      <div className="modal is-active" onKeyDown={handleKeyDown}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit Organization</p>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <Input formik={f} name="name" type="text" autoFocus />
              </div>
              <FieldError formik={f} name="name" />
            </div>
            <div className="field">
              <label className="label">Street</label>
              <div className="control">
                <Input formik={f} name="address.street" type="text" />
              </div>
              <FieldError formik={f} name="address.street" />
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">City</label>
                  <div className="control">
                    <Input formik={f} name="address.city" type="text" />
                  </div>
                  <FieldError formik={f} name="address.city" />
                </div>
              </div>
              <div className="column is-2">
                <div className="field">
                  <label className="label">State</label>
                  <div className="control">
                    <Input formik={f} name="address.state" type="text" />
                  </div>
                  <FieldError formik={f} name="address.state" />
                </div>
              </div>
              <div className="column is-2">
                <div className="field">
                  <label className="label">Zip</label>
                  <div className="control">
                    <Input formik={f} name="address.zip" type="text" />
                  </div>
                  <FieldError formik={f} name="address.zip" />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Phone Number</label>
              <div className="control">
                <Input formik={f} name="phone" type="text" />
              </div>
              <FieldError formik={f} name="phone" />
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button" type="button" onClick={onClose}>Cancel</button>
            <button className="button is-primary" type="submit">Update</button>
          </footer>
        </div>
      </div>
    </Form>
  );
}

export default EditOrganizationModal;
