import { format, parse } from "date-fns";

function localizeDateString(dateStr) {
  if (!dateStr) {
    return '';
  }
  return parse(dateStr, "yyyy-MM-dd", new Date()).toLocaleDateString();
}

function localizeTimeString(timeStr) {
  if (!timeStr) {
    return '';
  }
  return parse(timeStr, "HH:mm", new Date()).toLocaleTimeString()
}

function localizeDateTimeStrings(dateStr, timeStr) {
  const localizedDate = localizeDateString(dateStr);
  const localizedTime = localizeTimeString(timeStr);
  return [localizedDate, localizedTime].filter(Boolean).join(' ');
}

function formatDateTimeLocal(date) {
  if (!date) {
    return '';
  }
  return format(date, "yyyy-MM-dd'T'HH:mm");
}

export { localizeDateString, localizeTimeString, localizeDateTimeStrings, formatDateTimeLocal };
