import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/auth-provider";

const InvitationsRedirect = ({ children }) => {
  const { profile } = useAuth();
  if (profile.invitations.length > 0) {
    return <Navigate to="/invitations" replace />;
  }
  return children;
};

export default InvitationsRedirect;
