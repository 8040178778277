import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="has-background-light px-5 py-5">
      <div className="columns">
        <div className="column">
          <div className="block is-flex is-align-items-center">
            <img src="/logo28.png" alt="logo" width="28" height="28"/>
            <p className="title is-5 pl-1">Supra Opus</p>
          </div>
          <div className="block">
            <p>Copyright © {new Date().getFullYear()} Supra Opus. All rights reserved.</p>
          </div>
        </div>
        <div className="column is-flex is-align-items-center is-justify-content-end">
          <nav>
            <NavLink to="/about" className="has-text-grey-dark">About Us</NavLink>
            <span className="mx-3 has-text-grey-light">|</span>
            <NavLink to="/contact" className="has-text-grey-dark">Contact Us</NavLink>
            <span className="mx-3 has-text-grey-light">|</span>
            <NavLink to="/disclaimer" className="has-text-grey-dark">Disclaimer</NavLink>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
