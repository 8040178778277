import { Navigate, Outlet } from "react-router-dom";
import Authenticated from "../auth/authenticated";
import OrganizationRoute from "../organizations/organization-route";
import { AdminLayout } from "./admin-layout";
import OrganizationPage from "./organization";
import SitesPage from "./sites";
import EmployeesPage from "./employees";
import UsersPage from "./users";
import InvitationsPage from "./invitations";
import ActivityPage from "./activity";

const routes = [
  {
    path: "/admin",
    element: <Authenticated><OrganizationRoute role="admin"><AdminLayout><Outlet/></AdminLayout></OrganizationRoute></Authenticated>,
    children: [
      { path: "", element: <Navigate to="employees" replace/>},
      { path: "organization", element: <OrganizationPage/> },
      { path: "sites", element: <SitesPage/> },
      { path: "employees", element: <EmployeesPage/> },
      { path: "users", element: <UsersPage/> },
      { path: "invitations", element: <InvitationsPage/> },
      { path: "activity", element: <ActivityPage/> }
    ]
  }
];

export default routes;
