import { NavLink } from "react-router-dom";

function TrainingLayout({ children }) {
  return (
    <div className="columns is-gapless">
      <div className="column is-narrow m-3">
        <aside className="menu">
          <p className="menu-label">
            Training
          </p>
          <ul className="menu-list">
            <li><NavLink to="/training/courses">Courses</NavLink></li>
            <li><NavLink to="/training/sessions">Sessions</NavLink></li>
          </ul>
        </aside>
      </div>
      <div className="column m-3">
        {children}
      </div>
    </div>
  );
}

export { TrainingLayout };
