import Chart from "react-apexcharts";
import { exercises } from "./data";

const options = {
  chart: {
    toolbar: {
      show: false
    },
    offsetY: -35
  },
  plotOptions: {
    bar: {
      borderRadius: 5,
      horizontal: true
    }
  },
  dataLabels: {
    formatter: (v) => v + '%'
  },
  xaxis: {
    categories: ['Warm-ups', 'Stretches'],
    labels: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  yaxis: {
    max: 100
  },
  tooltip: {
    enabled: false
  }
};

function getProgress(completed, goal) {
  return Math.round(Math.min((completed / goal) * 100, 100));
}

function TodayChart({ completed }) {
  if (!completed) {
    return <div>Loading...</div>;
  }

  const series = [{ data: [
    getProgress(completed.warmups.length, exercises.warmup.goal),
    getProgress(completed.stretches.length, exercises.stretch.goal)
  ]}];

  return (
    <Chart type="bar" height="180" series={series} options={options} />
  );
}

export default TodayChart;
