const exercises = {
  'warmup': {
    name: 'Warm-up',
    videoUrl: 'https://firebasestorage.googleapis.com/v0/b/supra-opus.appspot.com/o/videos%2Fe5288f5c-1386-49e8-adf3-7c964fe7367e.mp4?alt=media&token=c227b931-6c13-4233-9de9-cbb32d3a5b36',
    goal: 3
  },
  'stretch': {
    name: 'Stretch',
    videoUrl: 'https://firebasestorage.googleapis.com/v0/b/supra-opus.appspot.com/o/videos%2F5760a7ff-c6c4-4158-9a23-e8b7408ef234.mp4?alt=media&token=1dd950ea-6ef7-46ce-a8e6-4928add937a2',
    goal: 2
  }
};

export { exercises };
