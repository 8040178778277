function DisclaimerPage() {
  return (
    <section className="section">
      <h1 className="title">Disclaimer</h1>
      <div className="content">
        <p>
          The information provided by this health application is for general
          information purposes only and is not intended to be a substitute for professional
          medical advice, diagnoses, or treatment. Always seek the advice of your physician or
          other qualified health provider with any questions you may have regarding a medical
          condition. Never disregard professional medical advice or delay in seeking it
          because something you have read on this application. If you think you may have a
          medical emergency, call your doctor or 911 immediately. The health application and
          its content are provided "as is" information.
        </p>
      </div>
    </section>
  );
}

export default DisclaimerPage;
