import { client } from "../api-client";

async function getSites(organizationId) {
  const response = await client.get(`/organizations/${organizationId}/sites`);
  return response.data;
}

async function getSite(organizationId, siteId) {
  const response = await client.get(`/organizations/${organizationId}/sites/${siteId}`);
  return response.data;
}

async function addSite(organizationId, site) {
  const response = await client.post(`/organizations/${organizationId}/sites`, site);
  return response.data;
}

async function updateSite(organizationId, siteId, site) {
  const response = await client.put(`/organizations/${organizationId}/sites/${siteId}`, site);
  return response.data;
}

export { getSites, getSite, addSite, updateSite };
