import _ from "lodash";
import { eachWeekOfInterval, endOfWeek, endOfYear, isFuture, startOfDay, startOfWeek, startOfYear } from "date-fns";
import Chart from "react-apexcharts";
import { exercises } from "../exercises/data";

function calculateWeeklyProgress(dates, goal, days = 5) {
  return _.chain(dates)
    .countBy(d => startOfDay(d).toISOString())
    .mapValues(v => Math.min(v, goal) / goal)
    .map((progress, day) => ({ day, progress }))
    .groupBy(({ day }) => startOfWeek(day).toISOString())
    .mapValues(d => _.chain(d).map('progress').sortBy().reverse().take(days).sum().value() / days)
    .map((progress, week) => ({ week, progress }))
    .value();
}

function getGoalProgressSeries(data) {
  const weeklyProgress = _.chain(data)
    .map(({ warmups, stretches }) => {
      return {
        warmups: calculateWeeklyProgress(warmups, exercises.warmup.goal),
        stretches: calculateWeeklyProgress(stretches, exercises.stretch.goal),
      };
    })
    .flatMap(({ warmups, stretches }) => [...warmups, ...stretches])
    .groupBy(({ week }) => week)
    .mapValues(v => Math.round(_.sumBy(v, 'progress') / v.length * 100))
    .value();

  const start = startOfWeek(startOfYear(Date.now()));
  const end = endOfWeek(endOfYear(Date.now()));
  const weeks = eachWeekOfInterval({ start, end });

  return weeks.map(week => {
    const progress = isFuture(endOfWeek(week)) ? null : (weeklyProgress[week.toISOString()] || 0);
    return { x: week, y: progress };
  });
}

const options = {
  chart: {
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
  },
  xaxis: {
    labels: {
      format: 'MMM'
    },
    type: 'datetime',
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4,
    labels: {
      formatter: (v) => v + '%',
    }
  }
};

function GoalProgressChart({ data }) {
  const series = [{
    name: "Goal Progress",
    data: getGoalProgressSeries(data)
  }];

  return (
    <Chart type="line" height="250" width="100%" series={series} options={options} />
  );
}

export default GoalProgressChart;
