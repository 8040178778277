import { useEffect, useState } from "react";
import { getOrganizationMemberships } from "../memberships/api";
import { useOrganization } from "../organizations/organization-provider";

function roleName(role) {
  switch (role) {
    case "admin":
      return "Administrator";
    default:
      return "User";
  }
}

function UsersPage() {
  const { organization } = useOrganization();
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const load = async () => {
      const users = await getOrganizationMemberships(organization.id);
      setUsers(users);
    };
    load();
  }, [organization]);

  if (!users) {
    return null;
  }

  return (
    <div className="container">
      <h1 className="title">Users</h1>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.email}>
              <td>{user.email}</td>
              <td>{roleName(user.role)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersPage;
