import Authenticated from "../auth/authenticated";
import IndexPage from ".";
import { Outlet } from "react-router-dom";

const routes = [
  {
    path: "/invitations",
    element: <Authenticated><Outlet/></Authenticated>,
    children: [
      { path: "", element: <IndexPage/> },
    ]
  }
];

export default routes;
