import { Link } from "react-router-dom";

function AboutPage() {
  return (
    <section className="section">
      <h1 className="title">Health and Wellness</h1>
      <h2 className="subtitle">Supra Opus</h2>
      <div className="content">
        <p>
          What does <em>Supra Opus</em> mean? In Latin, Supra Opus translates to
          'above (or before) work'. This is the fundamental core value of what
          we believe in. That Health and Wellness comes above or before work.
          Most companies say this in the common phrase "Safety First!"
        </p>
        <p>
          The purpose of Supra Opus is to increase customer safety compliance
          while simultaneously promoting an environment of productivity. We
          believe that providing EHS expertise and software tools are fundamental
          elements of the human experience. Supra Opus is a space that integrates
          EHS Consultancy and EHS software solutions into the workplace.
        </p>
        <p>
          We believe this can be achieved through personalized professional EHS services
          and software solutions to help our customers focus on core business objectives.
        </p>
        <p>
          Visit our <Link to="/contact">Contact Us</Link> page to schedule an appointment.
        </p>
        <div className="has-text-centered">
          <img src="/images/building.jpg" width="500" height="300" alt="building"/>
        </div>
      </div>
    </section>
  );
}

export default AboutPage;
