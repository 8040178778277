import { useEffect, useState } from "react";
import { getIncidents } from "./api";
import { useOrganization } from "../organizations/organization-provider";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { localizeDateTimeStrings } from "../utils";

function ListPage() {
  const { organization } = useOrganization();
  const [incidents, setIncidents] = useState(null);

  useEffect(() => {
    const load = async () => {
      const incidents = await getIncidents(organization.id);
      setIncidents(incidents);
    };
    load();
  }, [organization]);

  if (!incidents) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Incidents</h1>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Date/Time</th>
            <th>Location</th>
            <th>Description</th>
            <th width="1%" align="right">
              <NavLink to="/incidents/new" className="button is-small is-primary">Add</NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          {incidents.map(incident => (
            <tr key={incident.id}>
              <td>{localizeDateTimeStrings(incident.date, incident.time)}</td>
              <td>{incident.location}</td>
              <td>{_.truncate(incident.description, {length: 50})}</td>
              <td>
                <div className="field is-grouped">
                  <p className="control">
                    <NavLink to={`/incidents/${incident.id}`} className="button is-small">View</NavLink>
                  </p>
                  <p className="control">
                    <NavLink to={`/incidents/${incident.id}/edit`} className="button is-small">Edit</NavLink>
                  </p>
                  <p className="control">
                    <button className="button is-small is-danger is-outlined" disabled>Delete</button>
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default ListPage;
