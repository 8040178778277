function ErrorPage({ title, message }) {
  return (
    <section className="section">
      <h1 className="title">{title}</h1>
      <h2 className="subtitle">{message}</h2>
    </section>
  );
}

export default ErrorPage;
