import Authenticated from "../auth/authenticated";
import OrganizationRoute from "../organizations/organization-route";
import ListPage from "./list";
import ViewPage from "./view";
import EditPage from "./edit";
import { Outlet } from "react-router-dom";

const routes = [
  {
    path: "/incidents",
    element: <Authenticated><OrganizationRoute><Outlet/></OrganizationRoute></Authenticated>,
    children: [
      { path: "", element: <ListPage/> },
      { path: ":id", element: <ViewPage/> },
      { path: "new", element: <EditPage/> },
      { path: ":id/edit", element: <EditPage/> }
    ]
  }
];

export default routes;
