import { useEffect, useState } from "react";
import { useOrganization } from "../organizations/organization-provider";
import { getIncident } from "./api";
import { useParams } from "react-router-dom";
import { localizeDateTimeStrings } from "../utils";

function ViewPage() {
  const { organization } = useOrganization();
  const { id } = useParams();
  const [incident, setIncident] = useState(null);

  useEffect(() => {
    const load = async () => {
      const incident = await getIncident(organization.id, id);
      setIncident(incident);
    };
    load();
  }, [organization, id]);

  if (!incident) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Incident</h1>
      <div className="block">
        <strong>Date/Time</strong>
        <p>{localizeDateTimeStrings(incident.date, incident.time)}</p>
      </div>
      <div className="block">
        <strong>Location</strong>
        <p>{incident.location}</p>
      </div>
      <div className="block">
        <strong>Description</strong>
        <p>{incident.description}</p>
      </div>
    </section>
  );
}

export default ViewPage;
