import './sentry';
import './firebase';
import './bulma-custom.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
import { MainLayout } from './layout/main-layout';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AuthProvider } from './auth/auth-provider';
import authRoutes from './auth/routes';
import userRoutes from './user/routes';
import adminRoutes from './admin/routes';
import dashboardRoutes from './dashboard/routes';
import exercisesRoutes from './exercises/routes';
import trainingRoutes from './training/routes';
import incidentsRoutes from './incidents/routes';
import invitationsRoutes from './invitations/routes';
import siteRoutes from './site/routes';
import { ToastContainer } from 'react-toastify';
import UpdateNotification from './site/update-notification';
import * as Sentry from "@sentry/react";
import { GlobalErrorBoundary } from './site/global-error-boundary';
import { OrganizationProvider } from './organizations/organization-provider';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

function Root() {
  return (
    <GlobalErrorBoundary>
      <AuthProvider>
        <OrganizationProvider>
          <MainLayout>
            <Outlet/>
          </MainLayout>
          <ToastContainer/>
          <UpdateNotification/>
        </OrganizationProvider>
      </AuthProvider>
    </GlobalErrorBoundary>
  );
}

const router = sentryCreateBrowserRouter([
  {
    element: <Root/>,
    children: [
      ...authRoutes,
      ...userRoutes,
      ...adminRoutes,
      ...dashboardRoutes,
      ...exercisesRoutes,
      ...trainingRoutes,
      ...incidentsRoutes,
      ...invitationsRoutes,
      ...siteRoutes
    ]
  }
]);

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
