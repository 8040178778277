import { client } from "../api-client";

async function getOrganizationInvitations(organizationId) {
  const response = await client.get(`/organizations/${organizationId}/invitations`);
  return response.data.invitations;
}

async function getUserInvitations() {
  const response = await client.get('/user/invitations');
  return response.data.invitations;
}

async function inviteUser(organizationId, email) {
  await client.post(`/organizations/${organizationId}/invitations`, { email });
}

async function acceptInvitation(organizationId, invitationId) {
  await client.post(`/organizations/${organizationId}/invitations/${invitationId}/accept`);
}

async function declineInvitation(organizationId, invitationId) {
  await client.post(`/organizations/${organizationId}/invitations/${invitationId}/decline`);
}

export {
  getOrganizationInvitations,
  getUserInvitations,
  inviteUser,
  acceptInvitation,
  declineInvitation
};
