import { useEffect, useState } from "react";
import { getTrainingCourses } from "./api";
import { useOrganization } from "../../organizations/organization-provider";
import { NavLink } from "react-router-dom";
import _ from "lodash";

function ListPage() {
  const { organization } = useOrganization();
  const [courses, setCourses] = useState(null);

  useEffect(() => {
    const load = async () => {
      const courses = await getTrainingCourses(organization.id);
      setCourses(courses);
    };
    load();
  }, [organization]);

  if (!courses) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Training Courses</h1>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th width="1%" align="right">
              <NavLink to="/training/courses/new" className="button is-small is-primary">Add</NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          {courses.map(course => (
            <tr key={course.id}>
              <td>{course.title}</td>
              <td>{_.truncate(course.description, {length: 50})}</td>
              <td>
                <div className="field is-grouped">
                  <p className="control">
                    <NavLink to={`/training/courses/${course.id}`} className="button is-small">View</NavLink>
                  </p>
                  <p className="control">
                    <NavLink to={`/training/courses/${course.id}/edit`} className="button is-small">Edit</NavLink>
                  </p>
                  <p className="control">
                    <button className="button is-small is-danger is-outlined" disabled>Delete</button>
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default ListPage;
