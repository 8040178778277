import { client } from "../api-client";

async function getOrganization(organizationId) {
  const response = await client.get(`/organizations/${organizationId}`);
  return response.data;
}

async function updateOrganization(organizationId, organization) {
  const response = await client.put(`/organizations/${organizationId}`, organization);
  return response.data;
}

export { getOrganization, updateOrganization };
