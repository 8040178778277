import { useAuth } from "../auth/auth-provider";
import { useOrganization } from "./organization-provider";
import ErrorPage from "../site/error";
import OrganizationSelector from "./organization-selector";

function OrganizationRoute({ role, children }) {
  const { organization } = useOrganization();
  const { profile } = useAuth();
  if (!organization) {
    if (profile.memberships.length === 0) {
      return <ErrorPage title="Forbidden" message="You do not have permission to access this page."/>;
    } else {
      return <OrganizationSelector/>;
    }
  }
  if (role && role !== organization.role) {
    return <ErrorPage title="Forbidden" message="You do not have permission to access this page."/>;
  }
  return children;
}

export default OrganizationRoute;
