import { useCallback, useEffect, useState } from "react";
import { getEmployees } from "../employees/api";
import { useOrganization } from "../organizations/organization-provider";
import EditEmployeeModal from "./edit-employee-modal";

function EmployeesPage() {
  const { organization } = useOrganization();
  const [employees, setEmployees] = useState(null);
  const [modal, setModal] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);

  const loadEmployees = useCallback(async () => {
    const employees = await getEmployees(organization.id);
    setEmployees(employees);
  }, [organization]);

  useEffect(() => {
    loadEmployees();
  }, [loadEmployees]);

  const openModal = (employeeId) => {
    setEmployeeId(employeeId);
    setModal(true);
  };

  const closeModal = async () => {
    await loadEmployees();
    setModal(false);
  };

  if (!employees) {
    return null;
  }

  return (
    <>
      {modal && <EditEmployeeModal id={employeeId} onClose={closeModal}/>}

      <div className="container">
        <h1 className="title">Employees</h1>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Name</th>
              <th>Job Title</th>
              <th width="1%"><button className="button is-small is-primary" onClick={() => openModal()}>Add</button></th>
            </tr>
          </thead>
          <tbody>
            {employees.map(employee => (
              <tr key={employee.id}>
                <td>{employee.name}</td>
                <td>{employee.jobTitle}</td>
                <td><button className="button is-small" onClick={() => openModal(employee.id)}>Edit</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default EmployeesPage;
