import { client } from "../api-client";

async function getEmployees(organizationId) {
  const response = await client.get(`/organizations/${organizationId}/employees`);
  return response.data;
}

async function getEmployee(organizationId, employeeId) {
  const response = await client.get(`/organizations/${organizationId}/employees/${employeeId}`);
  return response.data;
}

async function addEmployee(organizationId, employee) {
  const response = await client.post(`/organizations/${organizationId}/employees`, employee);
  return response.data;
}

async function updateEmployee(organizationId, employeeId, employee) {
  const response = await client.put(`/organizations/${organizationId}/employees/${employeeId}`, employee);
  return response.data;
}

export { getEmployees, getEmployee, addEmployee, updateEmployee };
