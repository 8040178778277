import { client } from "../api-client";

async function upload(organizationId, file) {
  const response = await client.post(`/organizations/${organizationId}/documents`, file, {
    headers: {
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${file.name}"`,
    },
    timeout: 60000
  });
  return response.data;
}

async function download(organizationId, doc) {
  const response = await client.get(`/organizations/${organizationId}/documents/${doc.id}`, {
    responseType: 'blob',
  });
  const href = URL.createObjectURL(response.data);
  try {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', doc.name);
    document.body.appendChild(link);
    try {
      link.click();
    }
    finally {
      document.body.removeChild(link);
    }
  }
  finally {
    URL.revokeObjectURL(href);
  }
}

export { upload, download };
