import Header from "./header";
import Footer from "./footer";

function MainLayout({ children }) {
  return (
    <div className="is-flex is-flex-direction-column" style={{ height: "100vh" }}>
      <div className="is-flex-grow-0">
        <Header/>
      </div>
      <div className="is-flex-grow-1">
        {children}
      </div>
      <div className="is-flex-grow-0">
        <Footer/>
      </div>
    </div>
  );
}

export { MainLayout };
