import Authenticated from "../auth/authenticated";
import IndexPage from ".";
import ViewPage from "./view";
import InvitationsRedirect from "../invitations/invitations-redirect";
import { Outlet } from "react-router-dom";

const routes = [
  {
    path: "/exercises",
    element: <Authenticated><InvitationsRedirect><Outlet/></InvitationsRedirect></Authenticated>,
    children: [
      { path: "", element: <IndexPage/> },
      { path: "warmup", element: <ViewPage type="warmup"/> },
      { path: "stretch", element: <ViewPage type="stretch"/> }
    ]
  }
];

export default routes;
