import { client } from "../../api-client";

async function getTrainingCourses(organizationId) {
  const response = await client.get(`/organizations/${organizationId}/training-courses`);
  return response.data;
}

async function getTrainingCourse(organizationId, trainingCourseId) {
  const response = await client.get(`/organizations/${organizationId}/training-courses/${trainingCourseId}`);
  return response.data;
}

async function addTrainingCourse(organizationId, course) {
  const response = await client.post(`/organizations/${organizationId}/training-courses`, course);
  return response.data;
}

async function updateTrainingCourse(organizationId, trainingCourseId, course) {
  const response = await client.put(`/organizations/${organizationId}/training-courses/${trainingCourseId}`, course);
  return response.data;
}

export { getTrainingCourses, getTrainingCourse, addTrainingCourse, updateTrainingCourse };
