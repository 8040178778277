import { useEffect, useState } from "react";
import { getTrainingSessions } from "./api";
import { getTrainingCourses } from "../courses/api";
import { useOrganization } from "../../organizations/organization-provider";
import { NavLink } from "react-router-dom";
import _ from "lodash";

function ListPage() {
  const { organization } = useOrganization();
  const [sessions, setSessions] = useState(null);
  const [courses, setCourses] = useState(null);

  useEffect(() => {
    const loadSessions = async () => {
      const sessions = await getTrainingSessions(organization.id);
      setSessions(sessions);
    };
    const loadCourses = async () => {
      const courses = await getTrainingCourses(organization.id);
      setCourses(_.keyBy(courses, 'id'));
    };
    loadSessions();
    loadCourses();
  }, [organization]);

  if (!(sessions && courses)) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Training Sessions</h1>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>Course</th>
            <th>Date/Time</th>
            <th>Location</th>
            <th>Instructor</th>
            <th width="1%" align="right">
              <NavLink to="/training/sessions/new" className="button is-small is-primary">Add</NavLink>
            </th>
          </tr>
        </thead>
        <tbody>
          {sessions.map(session => (
            <tr key={session.id}>
              <td>{courses[session.courseId].title}</td>
              <td>{session.date.toLocaleString()}</td>
              <td>{session.location}</td>
              <td>{session.instructor}</td>
              <td>
                <div className="field is-grouped">
                  <p className="control">
                    <NavLink to={`/training/sessions/${session.id}`} className="button is-small">View</NavLink>
                  </p>
                  <p className="control">
                    <NavLink to={`/training/sessions/${session.id}/edit`} className="button is-small">Edit</NavLink>
                  </p>
                  <p className="control">
                    <button className="button is-small is-danger is-outlined" disabled>Delete</button>
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default ListPage;
