import { useCallback, useEffect, useState } from "react";
import { useOrganization } from "../organizations/organization-provider";
import { getOrganization } from "../organizations/api";
import EditOrganizationModal from "./edit-organization-modal";

function OrganizationPage() {
  const { organization: { id: organizationId } } = useOrganization();
  const [organization, setOrganization] = useState(null);
  const [modal, setModal] = useState(false);

  const loadOrganization = useCallback(async () => {
    const organization = await getOrganization(organizationId);
    setOrganization(organization);
  }, [organizationId]);

  useEffect(() => {
    loadOrganization();
  }, [loadOrganization]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = async () => {
    await loadOrganization();
    setModal(false);
  };

  if (!organization) {
    return null;
  }

  return (
    <>
      {modal && <EditOrganizationModal onClose={closeModal}/>}
      <div className="container">
        <h1 className="title">Organization</h1>
        <div className="block">
          <strong>Name</strong>
          <p>{organization.name}</p>
        </div>
        <div className="block">
          <strong>Address</strong>
          <p>{organization.address?.street}</p>
          <p>{[organization.address?.city, organization.address?.state, organization.address?.zip].filter(Boolean).join(', ')}</p>
        </div>
        <div className="block">
          <strong>Phone Number</strong>
          <p>{organization.phone}</p>
        </div>
        <button className="button is-primary" onClick={openModal}>Edit</button>
      </div>
    </>
  );
}

export default OrganizationPage;
