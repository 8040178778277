import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../organizations/organization-provider";
import { addIncident, getIncident, updateIncident } from "./api";
import { getEmployees } from "../employees/api";
import { getSites } from "../sites/api";
import { useFormik } from "formik";
import { Form, Input, Textarea, FieldError, Select } from "../forms";
import * as yup from "yup";
import { useEffect, useState } from "react";

const schema = yup.object({
  date: yup.string(),
  time: yup.string(),
  timeEmployeeBeganWork: yup.string(),
  location: yup.string().label('Location').required(),
  description: yup.string().label('Description').required(),
  beforeIncident: yup.string(),
  whatHappened: yup.string(),
  injuryOrIllness: yup.string(),
  objectSubstance: yup.string(),
  dateOfDeath: yup.string(),
  outcome: yup.string(),
  daysAwayFromWork: yup.number().integer(),
  daysOnJobTransferOrRestriction: yup.number().integer(),
  type: yup.string(),
  employee: yup.string(),
  site: yup.string(),
  treatment: yup.object({
    physician: yup.string(),
    facility: yup.string(),
    address: yup.object({
      street: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string()
    }),
    emergencyRoom: yup.string(),
    hospitalizedOvernight: yup.string(),
  }),
});

const outcomeOptions = [
  { value: "", label: "" },
  { value: "Death", label: "Death" },
  { value: "DaysAwayFromWork", label: "Days away from work" },
  { value: "JobTransferOrRestriction", label: "Job transfer or restriction (remained at work)" },
  { value: "OtherRecordableCase", label: "Other recordable case (remained at work)" }
];

const typeOfIncidentOptions = [
  { value: "", label: "" },
  { value: "Injury", label: "Injury" },
  { value: "SkinDisorder", label: "Skin disorder" },
  { value: "RespiratoryCondition", label: "Respiratory condition" },
  { value: "Poisoning", label: "Poisoning" },
  { value: "HearingLoss", label: "Hearing loss" },
  { value: "AllOtherIllnesses", label: "All other illnesses" },
];

function HelpTooltip({ children }) {
  return (
    <span className="icon has-tooltip-multiline has-text-grey" data-tooltip={children}>
      <i className="fas fa-question-circle"></i>
    </span>
  );
}

function TreatmentFieldset({ formik: f }) {
  return (
    <fieldset className="box" disabled={f.isSubmitting}>
      <legend className="label has-background-white px-1">Treatment</legend>
      <div className="field">
        <label className="label">Name of physician or other health care professional</label>
        <div className="control">
          <Input formik={f} name="treatment.physician" type="text" placeholder="Name of physician or other health care professional" />
        </div>
        <FieldError formik={f} name="treatment.physician" />
      </div>
      <title className="label">If treatment was given away from the worksite, where was it given?</title>
      <div className="field">
        <label className="label">Facility</label>
        <div className="control">
          <Input formik={f} name="treatment.facility" type="text" placeholder="Facility" />
        </div>
        <FieldError formik={f} name="treatment.facility" />
      </div>
      <div className="field">
        <label className="label">Street</label>
        <div className="control">
          <Input formik={f} name="treatment.address.street" type="text" placeholder="Street" />
        </div>
        <FieldError formik={f} name="treatment.address.street" />
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">City</label>
            <div className="control">
              <Input formik={f} name="treatment.address.city" type="text" placeholder="City" />
            </div>
            <FieldError formik={f} name="treatment.address.city" />
          </div>
        </div>
        <div className="column is-2">
          <div className="field">
            <label className="label">State</label>
            <div className="control">
              <Input formik={f} name="treatment.address.state" type="text" placeholder="State" />
            </div>
            <FieldError formik={f} name="treatment.address.state" />
          </div>
        </div>
        <div className="column is-2">
          <div className="field">
            <label className="label">Zip</label>
            <div className="control">
              <Input formik={f} name="treatment.address.zip" type="text" placeholder="Zip" />
            </div>
            <FieldError formik={f} name="treatment.address.zip" />
          </div>
        </div>
      </div>
      <div className="field">
        <label className="label">Was employee treated in an emergency room?</label>
        <div className="control">
          <label className="radio">
            <input type="radio" name="treatment.emergencyRoom" value="yes" />
            <span className="ml-1">Yes</span>
          </label>
          <label className="radio">
            <input type="radio" name="treatment.emergencyRoom" value="no" />
            <span className="ml-1">No</span>
          </label>
        </div>
        <FieldError formik={f} name="treatment.emergencyRoom" />
      </div>
      <div className="field">
        <label className="label">Was employee hospitalized overnight as an in-patient?</label>
        <div className="control">
          <label className="radio">
            <input type="radio" name="treatment.hospitalizedOvernight" value="yes" />
            <span className="ml-1">Yes</span>
          </label>
          <label className="radio">
            <input type="radio" name="treatment.hospitalizedOvernight" value="no" />
            <span className="ml-1">No</span>
          </label>
        </div>
        <FieldError formik={f} name="treatment.hospitalizedOvernight" />
      </div>
    </fieldset>
  );
}

function GeneralFieldset({ formik: f, employees, sites }) {
  return (
    <fieldset className="box" disabled={f.isSubmitting}>
      <legend className="label has-background-white px-1">General</legend>
      <div className="field">
        <label className="label">Site</label>
        <div className="control">
          <Select formik={f} name="site" options={sites} valueKey="id" labelKey="name" includeEmpty={sites.length > 1} autoFocus />
        </div>
        <FieldError formik={f} name="site" />
      </div>
      <div className="field">
        <label className="label">Employee</label>
        <div className="control">
          <Select formik={f} name="employee" options={employees} valueKey="id" labelKey="name" includeEmpty />
        </div>
        <FieldError formik={f} name="employee" />
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Date of incident</label>
            <div className="control">
              <Input formik={f} name="date" type="date" />
            </div>
            <FieldError formik={f} name="date" />
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Time of incident</label>
            <div className="control">
              <Input formik={f} name="time" type="time" />
            </div>
            <FieldError formik={f} name="time" />
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Time employee began work</label>
            <div className="control">
              <Input formik={f} name="timeEmployeeBeganWork" type="time" />
            </div>
            <FieldError formik={f} name="timeEmployeeBeganWork" />
          </div>
        </div>
      </div>
      <div className="field">
        <label className="label">
          Location
          <HelpTooltip>(e.g., Loading dock north end)</HelpTooltip>
        </label>
        <div className="control">
          <Input formik={f} name="location" type="text" placeholder="Location" />
        </div>
        <FieldError formik={f} name="location" />
      </div>
      <div className="field">
        <label className="label">
          Description
          <HelpTooltip>
            Describe injury or illness, parts of body affected, and object/substance that
            directly injured or made person ill (e.g., Second degree burns on right forearm
            from acetylene torch)
          </HelpTooltip>
        </label>
        <div className="control">
          <Textarea formik={f} name="description" placeholder="Description" rows={3} />
        </div>
        <FieldError formik={f} name="description" />
      </div>
      <div className="field">
        <label className="label">
          What was the employee doing just before the incident occurred?
          <HelpTooltip>
            Describe the activity, as well as the tools, equipment, or material the employee was
            using. Be specific. Examples: "climbing a ladder while carrying roofing materials";
            "spraying chlorine from hand sprayer"; "daily computer key-entry."
          </HelpTooltip>
        </label>
        <div className="control">
          <Textarea formik={f} name="beforeIncident" placeholder="" rows={3} />
        </div>
        <FieldError formik={f} name="beforeIncident" />
      </div>
      <div className="field">
        <label className="label">
          What happened?
          <HelpTooltip>
            Tell us how the injury occurred. Examples: "When ladder slipped on wet floor, worker
            fell 20 feet"; "Worker was sprayed with chlorine when gasket broke during replacement";
            "Worker developed soreness in wrist over time."
          </HelpTooltip>
        </label>
        <div className="control">
          <Textarea formik={f} name="whatHappened" placeholder="" rows={3} />
        </div>
        <FieldError formik={f} name="whatHappened" />
      </div>
      <div className="field">
        <label className="label">
          What was the injury or illness?
          <HelpTooltip>
            Tell us the part of the body that was affected and how it was affected.
            Examples: "strained back"; "chemical burn, hand"; "carpal tunnel syndrome."
          </HelpTooltip>
        </label>
        <div className="control">
          <Textarea formik={f} name="injuryOrIllness" placeholder="" rows={3} />
        </div>
        <FieldError formik={f} name="injuryOrIllness" />
      </div>
      <div className="field">
        <label className="label">
          What object or substance directly harmed the employee?
          <HelpTooltip>
            Examples: "concrete floor"; "chlorine"; "radial arm saw."
            If this question does not apply to the incident, leave it blank.
          </HelpTooltip>
        </label>
        <div className="control">
          <Textarea formik={f} name="objectSubstance" placeholder="" rows={3} />
        </div>
        <FieldError formik={f} name="objectSubstance" />
      </div>
      <div className="field">
        <label className="label">Type of Incident</label>
        <div className="control">
          <Select formik={f} name="type" options={typeOfIncidentOptions} />
        </div>
        <FieldError formik={f} name="type" />
      </div>
      <div className="field">
        <label className="label">
          Outcome
          <HelpTooltip>Select one based on the most serious outcome.</HelpTooltip>
        </label>
        <div className="control">
          <Select formik={f} name="outcome" options={outcomeOptions} />
        </div>
        <FieldError formik={f} name="outcome" />
      </div>
      <div className="field">
        <label className="label">
          Date of death
          <HelpTooltip>
            If the employee died, when did death occur?
          </HelpTooltip>
        </label>
        <div className="control">
          <Input formik={f} name="dateOfDeath" type="date" />
        </div>
        <FieldError formik={f} name="dateOfDeath" />
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Days away from work</label>
            <div className="control">
              <Input formik={f} name="daysAwayFromWork" type="number" />
            </div>
            <FieldError formik={f} name="daysAwayFromWork" />
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Days on job transfer or restriction</label>
            <div className="control">
              <Input formik={f} name="daysOnJobTransferOrRestriction" type="number" />
            </div>
            <FieldError formik={f} name="daysOnJobTransferOrRestriction" />
          </div>
        </div>
      </div>
    </fieldset>
  );
}

function EditPage() {
  const { organization } = useOrganization();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState(null);
  const [sites, setSites] = useState(null);
  const navigate = useNavigate();

  const f = useFormik({
    initialValues: schema.default(),
    validationSchema: schema,
    onSubmit: async (values) => {
      if (id) {
        await updateIncident(organization.id, id, values);
      } else {
        await addIncident(organization.id, values);
      }
      navigate("/incidents");
    }
  });

  useEffect(() => {
    const load = async () => {
      const [incident, employees, sites] = await Promise.all([
        id ? getIncident(organization.id, id) : Promise.resolve(null),
        getEmployees(organization.id),
        getSites(organization.id)
      ]);
      if (incident) {
        f.setValues(incident);
      }
      setEmployees(employees);
      setSites(sites);
      setLoading(false);
    };
    load();
  }, [organization, id]);

  if (loading) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Incident</h1>
      <Form formik={f}>
        <GeneralFieldset formik={f} employees={employees} sites={sites} />
        <TreatmentFieldset formik={f} />
        <fieldset disabled={f.isSubmitting}>
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-primary" type="submit">Save</button>
            </div>
            <div className="control">
              <NavLink to="/incidents" className="button">Cancel</NavLink>
            </div>
          </div>
        </fieldset>
      </Form>
    </section>
  );
}

export default EditPage;
