import { useEffect, useState } from "react";
import { addEmployee, getEmployee, updateEmployee } from "../employees/api";
import { useOrganization } from "../organizations/organization-provider";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAsyncErrorBoundary } from "../site/global-error-boundary";
import { FieldError, Form, Input, Select } from "../forms";

const schema = yup.object({
  name: yup.string().label('Name').required(),
  jobTitle: yup.string(),
  address: yup.object({
    street: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string()
  }),
  dateOfBirth: yup.string(),
  dateOfHire: yup.string(),
  gender: yup.string(),
});

const genderOptions = [
  { value: '', label: '' },
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
  { value: 'O', label: 'Other' },
];

function EditEmployeeModal({ id, onClose }) {
  const { organization } = useOrganization();
  const [loading, setLoading] = useState(true);
  const asyncErrorBoundary = useAsyncErrorBoundary();

  const f = useFormik({
    initialValues: schema.default(),
    validationSchema: schema,
    onSubmit: asyncErrorBoundary(async (values) => {
      if (id) {
        await updateEmployee(organization.id, id, values);
      } else {
        await addEmployee(organization.id, values);
      }
      onClose();
    })
  });

  useEffect(() => {
    const load = async () => {
      if (loading && id) {
        const employee = await getEmployee(organization.id, id);
        f.setValues(employee);
      }
      setLoading(false);
    };
    load();
  }, [organization, id, loading, f]);

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Form formik={f}>
      <div className="modal is-active" onKeyDown={handleKeyDown}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{id ? 'Edit' : 'Add'} Employee</p>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <Input formik={f} name="name" type="text" autoFocus />
              </div>
              <FieldError formik={f} name="name" />
            </div>
            <div className="field">
              <label className="label">Job Title</label>
              <div className="control">
                <Input formik={f} name="jobTitle" type="text" />
              </div>
              <FieldError formik={f} name="jobTitle" />
            </div>
            <div className="field">
              <label className="label">Street</label>
              <div className="control">
                <Input formik={f} name="address.street" type="text" />
              </div>
              <FieldError formik={f} name="address.street" />
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">City</label>
                  <div className="control">
                    <Input formik={f} name="address.city" type="text" />
                  </div>
                  <FieldError formik={f} name="address.city" />
                </div>
              </div>
              <div className="column is-2">
                <div className="field">
                  <label className="label">State</label>
                  <div className="control">
                    <Input formik={f} name="address.state" type="text" />
                  </div>
                  <FieldError formik={f} name="address.state" />
                </div>
              </div>
              <div className="column is-2">
                <div className="field">
                  <label className="label">Zip</label>
                  <div className="control">
                    <Input formik={f} name="address.zip" type="text" />
                  </div>
                  <FieldError formik={f} name="address.zip" />
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">Date of Birth</label>
                  <div className="control">
                    <Input formik={f} name="dateOfBirth" type="date" />
                  </div>
                  <FieldError formik={f} name="dateOfBirth" />
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Date Hired</label>
                  <div className="control">
                    <Input formik={f} name="dateOfHire" type="date" />
                  </div>
                  <FieldError formik={f} name="dateOfHire" />
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Gender</label>
              <div className="control">
                <Select formik={f} name="gender" options={genderOptions} />
              </div>
              <FieldError formik={f} name="gender" />
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button" type="button" onClick={onClose}>Cancel</button>
            <button className="button is-primary" type="submit">{id ? 'Update' : 'Add'}</button>
          </footer>
        </div>
      </div>
    </Form>
  );
}

export default EditEmployeeModal;
