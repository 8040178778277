import ListPage from "./list";
import ViewPage from "./view";
import EditPage from "./edit";

const routes = [
  {
    path: "sessions",
    children: [
      { path: "", element: <ListPage/> },
      { path: ":id", element: <ViewPage/> },
      { path: "new", element: <EditPage/> },
      { path: ":id/edit", element: <EditPage/> }
    ]
  }
];

export default routes;
