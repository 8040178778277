import { useUpdateCheck } from "react-update-notification";
import { toast } from "react-toastify";
import { useEffect } from "react";

function UpdateNotification() {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 1000 * 60 * 60, // 1 hour
    ignoreServerCache: true,
  });

  useEffect(() => {
    if (status === 'available') {
      toast(<UpdateNotificationMessage reloadPage={reloadPage}/>, {
        toastId: 'update-notification-message',
        position: 'top-center',
        autoClose: false,
        closeButton: false,
        draggable: false,
        closeOnClick: false,
      });
    }
  }, [status, reloadPage]);

  return null;
}

function UpdateNotificationMessage({ reloadPage }) {
  return (
    <div className="is-flex is-flex-direction-column is-align-items-center">
      <div className="my-1">
        There is a new version of Supra Opus available.
      </div>
      <div className="my-1">
        <button className="button is-info" onClick={reloadPage}>Update Now</button>
      </div>
    </div>
  );
}

export default UpdateNotification;
