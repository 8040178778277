import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../../organizations/organization-provider";
import { addTrainingCourse, getTrainingCourse, updateTrainingCourse } from "./api";
import { useFormik } from "formik";
import { Form, Input, Textarea, FieldError } from "../../forms";
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import { upload } from "../../documents/api";
import { useAsyncErrorBoundary } from "../../site/global-error-boundary";
import { toast } from "react-toastify";

const MAX_FILE_SIZE_MB = 30;

const schema = yup.object({
  title: yup.string().label('Title').required(),
  description: yup.string().nullable(),
  document: yup.object().label('Document').test('is-valid-file-size', '${label} exceeds maximum file size', (value) => {
    return !(value?.file?.size > MAX_FILE_SIZE_MB * 1024 * 1024);
  }).nullable(),
});

function EditPage() {
  const { organization } = useOrganization();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const uploadToast = useRef(null);
  const navigate = useNavigate();
  const asyncErrorBoundary = useAsyncErrorBoundary();

  const f = useFormik({
    initialValues: schema.default(),
    validationSchema: schema,
    onSubmit: asyncErrorBoundary(async (values) => {
      const data = {
        ...values,
        document: await uploadDocument(values.document),
      }
      if (id) {
        await updateTrainingCourse(organization.id, id, data);
      } else {
        await addTrainingCourse(organization.id, data);
      }
      navigate("/training/courses");
    })
  });

  const uploadDocument = async (document) => {
    const { file, ...rest } = document || {};
    if (file) {
      uploadToast.current = toast.loading("Uploading document...", { position: 'top-center' });
      try {
        const { id } = await upload(organization.id, file);
        return { ...rest, id };
      }
      finally {
        toast.dismiss(uploadToast.current);
      }
    } else {
      return document;
    }
  }

  const handleFileChange = async (event) => {
    const [file] = event.target.files;
    if (file) {
      f.setFieldValue("document", {
        file: file,
        name: file.name,
        size: file.size,
        type: file.type,
      });
    }
  };

  const handleFileClear = (event) => {
    event.preventDefault();
    f.setFieldValue("document", null);
  };

  useEffect(() => {
    const load = async () => {
      if (loading && id) {
        const course = await getTrainingCourse(organization.id, id);
        f.setValues(course);
      }
      setLoading(false);
    };
    load();
  }, [organization, id, loading, f]);

  if (loading) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Training Course</h1>
      <Form formik={f}>
        <fieldset disabled={f.isSubmitting}>
          <div className="field">
            <label className="label">Title</label>
            <div className="control">
              <Input formik={f} name="title" type="text" placeholder="Title" autoFocus />
            </div>
            <FieldError formik={f} name="title" />
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <Textarea formik={f} name="description" placeholder="Description" rows={3} />
            </div>
            <FieldError formik={f} name="description" />
          </div>
          <div className="field">
            <label className="label">Document</label>
            <div className="control">
              <div className="file has-name is-fullwidth">
                  <label className="file-label">
                    <input className="file-input" type="file" name="document" onChange={handleFileChange} />
                    <span className="file-cta">
                      <span className="file-label">Choose a file...</span>
                    </span>
                    <span className="file-name" style={{ borderTopRightRadius: "0", borderBottomRightRadius: "0", borderRight: "0" }}>
                      {f.values.document?.name}
                    </span>
                  </label>
                  <button className="button" style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }} onClick={handleFileClear}>
                    <span className="icon">
                      <i className="fas fa-times"></i>
                    </span>
                  </button>
              </div>
            </div>
            <p className="help">(Maximum file size: {MAX_FILE_SIZE_MB}MB)</p>
            <FieldError formik={f} name="document" />
          </div>
          <div className="field is-grouped">
            <div className="control">
              <button className="button is-primary" type="submit">Save</button>
            </div>
            <div className="control">
              <NavLink to="/training/courses" className="button">Cancel</NavLink>
            </div>
          </div>
        </fieldset>
      </Form>
    </section>
  );
}

export default EditPage;
