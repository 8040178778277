import { NavLink } from "react-router-dom";

function AdminLayout({ children }) {
  return (
    <div className="columns is-gapless">
      <div className="column is-narrow m-3">
        <aside className="menu">
          <p className="menu-label">
            Administration
          </p>
          <ul className="menu-list">
            <li><NavLink to="/admin/organization">Organization</NavLink></li>
            <li><NavLink to="/admin/sites">Sites</NavLink></li>
            <li><NavLink to="/admin/employees">Employees</NavLink></li>
            <li><NavLink to="/admin/users">Users</NavLink></li>
            <li><NavLink to="/admin/invitations">Invitations</NavLink></li>
          </ul>
          <p className="menu-label">
            Exercises
          </p>
          <ul className="menu-list">
            <li><NavLink to="/admin/activity">Activity</NavLink></li>
          </ul>
        </aside>
      </div>
      <div className="column m-3">
        {children}
      </div>
    </div>
  );
}

export { AdminLayout };
