import { useEffect } from "react";
import { acceptInvitation, declineInvitation } from "./api";
import { useAuth } from "../auth/auth-provider";
import { useNavigate } from "react-router-dom";

function IndexPage() {
  const { profile, loadProfile } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile.invitations.length === 0) {
      navigate('/dashboard');
    }
  }, [profile, navigate]);

  const handleAccept = async (organizationId, invitationId) => {
    await acceptInvitation(organizationId, invitationId);
    loadProfile();
  };

  const handleDecline = async (organizationId, invitationId) => {
    await declineInvitation(organizationId, invitationId);
    loadProfile();
  };

  return (
    <div className="section">
      <div className="columns">
        <div className="column is-one-third is-offset-one-third">
          {profile.invitations.map(i => (
            <div key={i.id} className="card mb-4">
              <div className="card-content">
                <p>You have been invited to join <strong>{i.organization.name}</strong> (by <strong>{i.invitedBy.email}</strong>)</p>
              </div>
              <div className="card-footer">
                <div className="card-footer-item buttons">
                  <button className="button is-success" onClick={() => handleAccept(i.organization.id, i.id)}>
                    Accept
                  </button>
                  <button className="button is-danger" onClick={() => handleDecline(i.organization.id, i.id)}>
                    Decline
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default IndexPage;
