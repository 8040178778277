import _ from "lodash";
import { eachWeekOfInterval, endOfWeek, endOfYear, isFuture, startOfWeek, startOfYear } from "date-fns";
import Chart from "react-apexcharts";

function getActiveUsersSeries(data) {
  const activeWeeklyUsers = _.chain(data)
    .flatMap(({ uid, warmups, stretches }) => {
      return [...warmups, ...stretches].map(d => ({ uid, week: startOfWeek(d).getTime().toString() }));
    })
    .uniqWith((a, b) => a.uid === b.uid && a.week === b.week)
    .countBy(({ week }) => week)
    .value();

  const start = startOfWeek(startOfYear(Date.now()));
  const end = endOfWeek(endOfYear(Date.now()));
  const weeks = eachWeekOfInterval({ start, end });

  return weeks.map(week => {
    const count = isFuture(endOfWeek(week)) ? null : (activeWeeklyUsers[week.getTime().toString()] || 0);
    return { x: week, y: count };
  });
}

const options = {
  chart: {
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
  },
  xaxis: {
    labels: {
      format: 'MMM'
    },
    type: 'datetime',
  },
  yaxis: {
    labels: {
      formatter: (v) => v?.toFixed(0),
    }
  }
};

function ActiveUsersChart({ data }) {
  const series = [{
    name: "Active Users",
    data: getActiveUsersSeries(data)
  }];

  return (
    <Chart type="line" height="250" width="100%" series={series} options={options} />
  );
}

export default ActiveUsersChart;
