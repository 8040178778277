import DashboardPage from ".";
import Authenticated from "../auth/authenticated";
import { Outlet } from "react-router-dom";

const routes = [
  {
    path: "/dashboard",
    element: <Authenticated><Outlet/></Authenticated>,
    children: [
      { path: "", element: <DashboardPage/> },
    ]
  }
];

export default routes;
