import { createContext, useContext, useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail, reauthenticateWithCredential, EmailAuthProvider, updatePassword, updateProfile } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getUserMemberships } from "../memberships/api";
import { getUserInvitations } from "../invitations/api";
import * as Sentry from "@sentry/react";

const AuthContext = createContext(null);
const auth = getAuth();
const analytics = getAnalytics();

const AuthProvider = ({ children }) => {
  const [authReady, setAuthReady] = useState(false);
  const [user, setUser] = useState(auth.currentUser);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (!authReady) {
      auth.authStateReady().then(() => setAuthReady(true));
    }
  });

  const loadProfile = async () => {
    const [memberships, invitations] = await Promise.all([
      getUserMemberships(),
      getUserInvitations()
    ]);
    setProfile({
      memberships,
      invitations
    });
  };

  useEffect(() => {
    if (user) {
      loadProfile();
    }
    return auth.onAuthStateChanged((newUser) => {
      if (newUser !== user) {
        setProfile(null);
        setUser(newUser);
      }
    });
  }, [user]);

  useEffect(() => {
    Sentry.setUser({ id: user?.uid, email: user?.email });
  }, [user, profile]);

  const signup = async ({ name, email, password, confirmPassword }) => {
    if (!name) {
      throw new Error('Name is required');
    }
    if (password !== confirmPassword) {
      throw new Error('Passwords do not match');
    }
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    logEvent(analytics, 'sign_up', { method: 'email' });
    await sendEmailVerification(user);
    await updateProfile(user, { displayName: name });
  };

  const login = async ({ email, password }) => {
    await signInWithEmailAndPassword(auth, email, password);
    logEvent(analytics, 'login', { method: 'email' });
  };

  const logout = async () => {
    await signOut(auth);
  };

  const forgotPassword = async ({ email }) => {
    await sendPasswordResetEmail(auth, email);
  };

  const changePassword = async ({ currentPassword, newPassword, confirmPassword }) => {
    if (!currentPassword) {
      throw new Error('Current Password is required');
    }
    if (!newPassword) {
      throw new Error('New Password is required');
    }
    if (!confirmPassword) {
      throw new Error('Confirm Password is required');
    }
    if (newPassword !== confirmPassword) {
      throw new Error('Passwords do not match');
    }
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
  };

  const updateUserProfile = async ({ displayName }) => {
    await updateProfile(user, { displayName });
  };

  if (!authReady) {
    return null;
  }

  const value = {
    user,
    profile,
    loadProfile,
    signup,
    login,
    logout,
    forgotPassword,
    changePassword,
    updateUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
