import { NavLink } from "react-router-dom";

function HomePage() {
  return (
    <>
      <section className="hero is-medium has-text-centered">
        <div className="hero-body">
          <div className="block">
            <img src="/logo192.png" alt="logo" width="128" height="128"/>
          </div>
          <div className="block">
            <h1 className="title is-1">Welcome To Supra Opus</h1>
          </div>
          <div className="block">
            <p className="subtitle is-4">
              Workplace Wellness Solutions
            </p>
          </div>
          <div className="block">
            <NavLink to="/signup" className="button is-primary is-large">Get started</NavLink>
          </div>
        </div>
      </section>

      <section className="hero is-medium has-text-centered">
        <div className="hero-body">
          <div className="block">
            <h2 className="title is-2">Safety and Ergonomic Solutions</h2>
          </div>
          <div className="block">
          <p className="subtitle is-4">Take Control of Your Workplace Wellness</p>
          </div>
          <div className="columns">
            <div className="column is-4">
              <img src="/images/stock1.jpg" alt="logo" width="196" height="294" style={{borderRadius: '100px'}}/>
              <p className="subtitle is-5 has-text-weight-bold mt-3">Develop Wellness Habits</p>
              <p>
                Supra Opus is your comprehensive partner in creating workplaces focused on well-being
                and productivity.
              </p>
            </div>
            <div className="column is-4">
              <img src="/images/stock2.jpg" alt="logo" width="196" height="294" style={{borderRadius: '100px'}}/>
              <p className="subtitle is-5 has-text-weight-bold mt-3">Preventative Health</p>
              <p>
                With a deep-rooted foundation in health and safety, our team is committed to elevating
                your business with software solutions and wellness programs.
              </p>
            </div>
            <div className="column is-4">
              <img src="/images/stock3.jpg" alt="logo" width="196" height="294" style={{borderRadius: '100px'}}/>
              <p className="subtitle is-5 has-text-weight-bold mt-3">Improve Focus and Balance</p>
              <p>
                We specialize in cultivating an environment where employee well-being and efficiency
                can flourish. Supra Opus is your trusted partner in fostering a workplace where
                productivity harmonizes with safety, health, and happiness.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomePage;
