import { client } from "../../api-client";

async function getTrainingSessions(organizationId) {
  const response = await client.get(`/organizations/${organizationId}/training-sessions`);
  return response.data.map(e => {
    return {
      ...e,
      date: new Date(e.date),
    }
  });
}

async function getTrainingSession(organizationId, trainingSessionId) {
  const response = await client.get(`/organizations/${organizationId}/training-sessions/${trainingSessionId}`);
  return {
    ...response.data,
    date: new Date(response.data.date),
  };
}

async function addTrainingSession(organizationId, session) {
  const response = await client.post(`/organizations/${organizationId}/training-sessions`, session);
  return response.data;
}

async function updateTrainingSession(organizationId, trainingSessionId, session) {
  const response = await client.put(`/organizations/${organizationId}/training-sessions/${trainingSessionId}`, session);
  return response.data;
}

export { getTrainingSessions, getTrainingSession, addTrainingSession, updateTrainingSession };
