import { useEffect, useState } from "react";
import { useOrganization } from "../../organizations/organization-provider";
import { getTrainingCourse } from "./api";
import { useParams } from "react-router-dom";
import { download } from "../../documents/api";

function ViewPage() {
  const { organization } = useOrganization();
  const { id } = useParams();
  const [course, setCourse] = useState(null);

  useEffect(() => {
    const load = async () => {
      const course = await getTrainingCourse(organization.id, id);
      setCourse(course);
    };
    load();
  }, [organization, id]);

  const handleDownload = () => {
    download(organization.id, course.document);
  };

  if (!course) {
    return null;
  }

  return (
    <section className="section">
      <h1 className="title">Training Course</h1>
      <div className="block">
        <strong>Title</strong>
        <p>{course.title}</p>
      </div>
      <div className="block">
        <strong>Description</strong>
        <p>{course.description}</p>
      </div>
      <div className="block">
        <strong>Document</strong>
        <p>
          {course.document && (
            <a onClick={handleDownload}>
              <span>{course.document.name}</span>
              <span className="icon">
                <i className="fas fa-download"></i>
              </span>
            </a>
          )}
        </p>
      </div>
    </section>
  );
}

export default ViewPage;
