import { client } from "../api-client";

async function getIncidents(organizationId) {
  const response = await client.get(`/organizations/${organizationId}/incidents`);
  return response.data;
}

async function getIncident(organizationId, incidentId) {
  const response = await client.get(`/organizations/${organizationId}/incidents/${incidentId}`);
  return response.data;
}

async function addIncident(organizationId, incident) {
  const response = await client.post(`/organizations/${organizationId}/incidents`, incident);
  return response.data;
}

async function updateIncident(organizationId, incidentId, incident) {
  const response = await client.put(`/organizations/${organizationId}/incidents/${incidentId}`, incident);
  return response.data;
}

export { getIncidents, getIncident, addIncident, updateIncident };
