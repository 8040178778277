function StretchInstructions() {
  return (
    <div className="content">
      <p className="subtitle is-5">Stretch</p>
      <p>
        Stretches are longer and slower motion intended to stretch the muscle fibers out without causing pain or discomfort. Each stretch should be held for about 7 seconds followed by a neutral or relaxed posture for 2-3 seconds. If available, stand up for the first section.
      </p>
      <ul>
        <li>Hip Thrusts <i>(Standing option)</i>
          <ul>
            <li><b>Extend:</b> Keeping the feet hips with distance and grounded, press hands into the lower back, resting on the hip bones. Slightly push the hips forward holding for 7 seconds</li>
            <li><b>Neutral:</b> Release back into neutral position with a upright back</li>
            <li>Repeat 2 times</li>
          </ul>
        </li>
        <li>Chin Tucks <i>(Sitting option)</i>
          <ul>
            <li><b>Extend:</b> While keeping the neck and back upright, extend (press) the chin forward, holding for 7 seconds</li>
            <li><b>Flex:</b> Flex the chin back slightly from neutral, holding for 7 seconds</li>
            <li><b>Neutral:</b> Release back into neutral position with an upright neck and back, relaxing for 2 seconds</li>
            <li>Repeat 2 times</li>
          </ul>
        </li>
        <li>Arms Over Head
          <ul>
            <li><b>Extend:</b> If available, put the hands on the back of the head with the elbows up and back, pull with upper back/neck, to feel an extension in the front upper body. Holding for 7 seconds</li>
            <li><b>Neutral:</b> Release out of the posture, relaxing the neck posture (option to keep hands behind head) for 2 seconds</li>
            <li>Repeat 2 times</li>
          </ul>
        </li>
        <li>Leg Extensions
          <ul>
            <li><b>Extend:</b> While sitting in a clear area, extend one leg up and point the toes towards the body, holding for 7 seconds</li>
            <li><b>Neutral:</b> Relax the foot back to the ground, 2 seconds</li>
            <li>Repeat 2 times, on each leg</li>
          </ul>
        </li>
        <li>Wrist Extensions
          <ul>
            <li><b>Flex:</b> With arms straight in front of the body, choose one hand to stretch. With the opposing hand, hold on the fingers and hand and pull the hand up and back toward the head, holding for 7 seconds.</li>
            <li><b>Opposite Direction:</b> Switch to holding the hand in the opposite direction and pulling the hand back and down towards the body holding for 7 seconds</li>
            <li><b>Neutral:</b> Relax back into the neutral position</li>
            <li>Repeat 2 times, on each hand</li>
          </ul>
        </li>
        <li>Neck Extensions
          <ul>
            <li><b>Extend:</b> With a upright neck and spine, move the head back and squeeze the shoulders together, holding for 7 seconds</li>
            <li><b>Flex:</b> Bring the spine to an upright position and slightly bring the chin forward for 7 seconds</li>
            <li><b>Neutral:</b> Relax into a neutral position with the neck and back in the upright position</li>
            <li>Repeat 2 times</li>
          </ul>
        </li>
        <li>Side Neck Extensions
          <ul>
            <li><b>Extend:</b> With the neck and back in the upright position, tilt the head to one shoulder (ear to the shoulder). Option to assist with hand. Holding for 7 seconds</li>
            <li><b>Opposite Direction:</b> Tilt the head to the other shoulder (ear to the shoulder). Option to assist with hand. Holding for 7 seconds</li>
            <li><b>Neutral:</b> Relax into the upright position</li>
            <li>Repeat 2 times</li>
          </ul>
        </li>
        <li>Rotating Wrist Exercise
          <ul>
            <li><b>Extend:</b> With arms straight out, hold hands into a fist. Pull hands down, then rotate outwards, holding for 7 seconds</li>
            <li><b>Neutral:</b> Return to the relaxed position with hands in front of wrists</li>
            <li>Repeat 2 times</li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default StretchInstructions;
