import { useEffect, useState } from "react";
import { getOrganizationInvitations, inviteUser } from "../invitations/api";
import { useOrganization } from "../organizations/organization-provider";

function InvitationsPage() {
  const { organization } = useOrganization();
  const [invitations, setInvitations] = useState(null);
  const [modal, setModal] = useState(false);

  const loadInvitations = async () => {
    const invitations = await getOrganizationInvitations(organization.id);
    setInvitations(invitations);
  };

  useEffect(() => {
    loadInvitations();
  }, [organization]);

  const openModal = () => {
    setModal(true);
  };

  const closeModal = async () => {
    await loadInvitations();
    setModal(false);
  };

  if (!invitations) {
    return null;
  }

  return (
    <>
      {modal && <InviteUserModal onClose={closeModal}/>}

      <div className="container">
        <h1 className="title">Invitations</h1>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Email</th>
              <th width="1%"><button className="button is-small is-primary" onClick={openModal}>Invite User</button></th>
            </tr>
          </thead>
          <tbody>
            {invitations.map(invitation => (
              <tr key={invitation.email}>
                <td>{invitation.email}</td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function InviteUserModal({ onClose }) {
  const { organization } = useOrganization();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInvite();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  const handleInvite = async () => {
    try {
      await inviteUser(organization.id, email);
      setEmail('');
      onClose();
    }
    catch (error) {
      if (error.response && error.response.status === 422) {
        setError(error.response.data.message);
      } else {
        throw error;
      }
    }
  };

  return (
    <div className="modal is-active" onKeyDown={handleKeyDown}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Invite User</p>
        </header>
        <section className="modal-card-body">
          {error && <div className="notification is-danger is-light">{error}</div>}
          <div className="field">
            <div className="control">
              <input className="input" type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} autoFocus/>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={onClose}>Cancel</button>
          <button className="button is-primary" onClick={handleInvite}>Invite</button>
        </footer>
      </div>
    </div>
  );
}

export default InvitationsPage;
