import { client } from "../api-client";

async function getUserMemberships() {
  const response = await client.get('/user/memberships');
  return response.data.memberships.map(m => {
    return {
      id: m.organization.id,
      name: m.organization.name,
      role: m.role
    }
  });
}

async function getOrganizationMemberships(organizationId) {
  const response = await client.get(`/organizations/${organizationId}/memberships`);
  return response.data.memberships;
}

export { getUserMemberships, getOrganizationMemberships };
