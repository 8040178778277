import { useState } from "react";
import ChangePasswordModal from "./change-password-modal";
import EditProfileModal from "./edit-profile-modal";
import { useAuth } from "../auth/auth-provider";

function ProfilePage() {
  const { user } = useAuth();
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  return (
    <>
      {editProfileModal && <EditProfileModal onClose={() => setEditProfileModal(false)}/>}
      {changePasswordModal && <ChangePasswordModal onClose={() => setChangePasswordModal(false)}/>}
      <section className="section has-text-centered">
        <div className="block has-text-grey-lighter">
          <i className="fa-solid fa-circle-user fa-6x"></i>
        </div>
        <div className="block">
          <h3 className="title">{user.displayName}</h3>
          <p className="subtitle">{user.email}</p>
        </div>
        <div className="block">
          <button className="button is-primary" onClick={() => setEditProfileModal(true)}>Edit Profile</button>
        </div>
        <div className="block">
          <button className="button is-primary" onClick={() => setChangePasswordModal(true)}>Change Password</button>
        </div>
      </section>
    </>
  );
}

export default ProfilePage;
