import { useState } from 'react';
import { useAuth } from '../auth/auth-provider';
import { translate } from '../firebase/errors';
import { toast } from 'react-toastify';
import * as Sentry from "@sentry/react";

function ChangePasswordModal({ onClose }) {
  const { changePassword } = useAuth();
  const [formData, setFormData] = useState({currentPassword: '', newPassword: '', confirmPassword: ''});
  const [error, setError] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleChangePassword();
    } else if (e.key === 'Escape') {
      onClose();
    }
  };

  const handleChangePassword = async () => {
    try {
      await changePassword(formData);
      setFormData({currentPassword: '', newPassword: '', confirmPassword: ''});
      toast('Password changed successfully', { type: 'success', autoClose: 2000 });
      onClose();
    }
    catch (e) {
      const { code } = e;
      const message = code ? translate(code) : e.message;
      setError(message || `An error has occurred: ${code}`);
      if (!message) {
        Sentry.captureException(e);
      }
    }
  };

  return (
    <div className="modal is-active" onKeyDown={handleKeyDown}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Change Password</p>
        </header>
        <section className="modal-card-body">
          {error && <div className="notification is-danger is-light">{error}</div>}
          <div className="field">
            <div className="control">
              <input className="input" type="password" name="currentPassword" value={formData.currentPassword} placeholder="Current Password" onChange={handleChange} autoFocus/>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input className="input" type="password" name="newPassword" value={formData.newPassword} placeholder="New Password" onChange={handleChange}/>
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input className="input" type="password" name="confirmPassword" value={formData.confirmPassword} placeholder="Confirm Password" onChange={handleChange}/>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button" onClick={onClose}>Cancel</button>
          <button className="button is-primary" onClick={handleChangePassword}>Change Password</button>
        </footer>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
