import { NavLink } from "react-router-dom";
import { exercises } from "./data";
import TodayChart from "./today-chart";
import { useEffect, useState } from "react";
import { getCompletedExercises } from "./api";
import { useOrganization } from "../organizations/organization-provider";
import _ from "lodash";

function getStart() {
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  return start;
}

function getTitle(completed) {
  // goal completed
  if (completed.warmups.length >= exercises.warmup.goal && completed.stretches.length >= exercises.stretch.goal) {
    return "You have completed your goal for today. Nice work!";
  }
  const last = _.max([...completed.warmups, ...completed.stretches]);
  // an exercise has been done today
  if (last) {
    const minutes = Math.floor((new Date() - last) / (1000 * 60));
    if (minutes < 10) {
      return "You just completed an exercise. Keep it up!";
    }
    else if (minutes < 60) {
      return "Your last exercise was less than an hour ago. Keep it up!";
    }
    else if (minutes < 120) {
      return "Your last exercise was less than 2 hours ago. Keep it up!";
    }
    else {
      return "Keep going! It's time for your next exercise.";
    }
  }
  // no exercises have been done today
  else {
    const beforeNoon = new Date().getHours() < 12;
    if (beforeNoon) {
      return "Good morning! Get the day started with a warm-up.";
    }
    else {
      return "Good afternoon! Let's get started with a warm-up.";
    }
  }
}

function IndexPage() {
  const { organization } = useOrganization();
  const [completed, setCompleted] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    const load = async () => {
      const start = getStart();
      const completed = await getCompletedExercises(organization?.id, start);
      setCompleted(completed);
    };
    load();
  }, [organization]);

  useEffect(() => {
    if (completed) {
      const updateTitle = () => setTitle(getTitle(completed));
      updateTitle();
      const timer = setInterval(updateTitle, 60 * 1000);
      return () => clearInterval(timer);
    }
  }, [completed]);

  return (
    <section className="section has-text-centered">
      <h2 className="title">{title ?? 'Exercises'}</h2>
      <div className="columns">
        <div className="column">
          <div className="block">
            <p className="is-size-6">
              Today
            </p>
          </div>
          <figure>
            <TodayChart completed={completed}/>
          </figure>
        </div>
        <div className="column">
          <div className="block">
            <p className="is-size-6">
              Your goal is {exercises.warmup.goal} warm-ups and {exercises.stretch.goal} stretches.
            </p>
          </div>
          <div className="block">
            <NavLink to="/exercises/warmup" className="button is-primary is-large">
              {exercises.warmup.name}
            </NavLink>
          </div>
          <div className="block">
            <NavLink to="/exercises/stretch" className="button is-primary is-large">
              {exercises.stretch.name}
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IndexPage;
