import { useEffect, useState } from 'react';
import cx from 'classnames';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth-provider';
import { useOrganization } from '../organizations/organization-provider';

function Header() {
  const { user, profile, logout } = useAuth();
  const { organization, setOrganization } = useOrganization();
  const navigate = useNavigate();
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => setIsActive(!isActive);

  useEffect(() => {
    if (isActive) {
      setIsActive(false);
    }
    const el = document.activeElement;
    if (el?.classList?.contains("navbar-item")) {
      el.blur();
    }
  }, [location]);

  const handleSelectOrganization = (id) => {
    setOrganization(id);
    setIsActive(false);
    navigate('/');
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <nav className="navbar is-light" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <NavLink className="navbar-item" to="/">
          <div className="block is-flex is-align-items-center">
            <img src="/logo28.png" alt="logo" width="28" height="28"/>
            <p className="title is-5 pl-1">Supra Opus</p>
          </div>
        </NavLink>
        <a role="button" className={cx('navbar-burger', { 'is-active': isActive })} aria-label="menu" aria-expanded="false" data-target="navbar" onClick={toggleActive}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbar" className={cx('navbar-menu', { 'is-active': isActive })}>
        <div className="navbar-end">
          {organization &&
            <>
              <NavLink className="navbar-item" to="/training">Training</NavLink>
              <NavLink className="navbar-item" to="/incidents">Incidents</NavLink>
            </>
          }
          {user && <NavLink className="navbar-item" to="/exercises">Exercises</NavLink>}
          <NavLink className="navbar-item" to="/about">About Us</NavLink>
          <NavLink className="navbar-item" to="/contact">Contact Us</NavLink>
          {user ?
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">
                <span className="icon mr-1">
                  <i className="fa-solid fa-circle-user"></i>
                </span>
                <span>
                  {user.displayName || user.email}
                  {organization && ` (${organization.name})`}
                </span>
              </a>
              <div className="navbar-dropdown is-right">
                {profile?.memberships.length > 1 &&
                  <>
                    {profile.memberships.map((o) => (
                      <a className="navbar-item" key={o.id} onClick={() => handleSelectOrganization(o.id)}>
                        {o.name}
                        {organization?.id === o.id && <span className="icon ml-1"><i className="fa-solid fa-check"></i></span>}
                      </a>
                    ))}
                    <hr className="navbar-divider" />
                  </>
                }
                <NavLink className="navbar-item" to="/user/profile">Profile</NavLink>
                <hr className="navbar-divider" />
                {organization?.role === 'admin' &&
                  <>
                    <NavLink className="navbar-item" to="/admin">Admin</NavLink>
                    <hr className="navbar-divider" />
                  </>
                }
                <a className="navbar-item" onClick={handleLogout}>Log out</a>
              </div>
            </div>
            :
            <div className="navbar-item">
              <div className="buttons">
                <NavLink className="button is-primary is-light" to="/login">Log in</NavLink>
                <NavLink className="button is-primary" to="/signup"><strong>Sign up</strong></NavLink>
              </div>
            </div>
          }
        </div>
      </div>
    </nav>
  );
}

export default Header;
