import { Link, Navigate } from "react-router-dom";
import { useOrganization } from "../organizations/organization-provider";

function DashboardPage() {
  const { organization } = useOrganization();

  if (!organization) {
    return <Navigate to="/exercises" replace/>;
  }

  return (
    <section className="container section">
      <h1 className="title has-text-centered">Welcome to Supra Opus!</h1>
      <h2 className="subtitle has-text-centered">{organization.name}</h2>
      <div className="columns">
        <div className="column">
          <div className="box" style={{ height: "100%" }}>
            <h3 className="title is-5">Training</h3>
            <ul>
              <li><Link to="/training/courses">View training courses</Link></li>
              <li><Link to="/training/sessions">View training sessions</Link></li>
            </ul>
          </div>
        </div>
        <div className="column">
          <div className="box" style={{ height: "100%" }}>
            <h3 className="title is-5">Incidents</h3>
            <ul>
              <li><Link to="/incidents">View incidents</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DashboardPage;
