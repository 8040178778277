import LoginPage from "./login";
import SignupPage from "./signup";
import ForgotPasswordPage from "./forgot-password";

const routes = [
  { path: "/login", element: <LoginPage/>},
  { path: "/signup", element: <SignupPage/>},
  { path: "/forgot-password", element: <ForgotPasswordPage/>},
];

export default routes;
