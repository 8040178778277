import AuthenticatedRedirect from "../auth/authenticated-redirect";
import DisclaimerPage from "./disclaimer";
import ErrorPage from "./error";
import HomePage from "./home";
import AboutPage from "./about";
import ContactPage from "./contact";

const routes = [
  { path: "/", element: <AuthenticatedRedirect><HomePage/></AuthenticatedRedirect>},
  { path: "/about", element: <AboutPage/>},
  { path: "/contact", element: <ContactPage/>},
  { path: "/disclaimer", element: <DisclaimerPage/>},
  { path: "*", element: <ErrorPage title="Not Found" message="The requested page was not found."/>}
];

export default routes;
